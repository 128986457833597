import React, { useState, useEffect } from "react";

import axios from "axios";

// import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Box from "@mui/material/Box";
import MDTypography from "components/MDTypography";

import PropTypes from "prop-types";

export default function SelectServer({ onSelectServer }) {
  SelectServer.propTypes = {
    onSelectServer: PropTypes.func.isRequired,
  };
  const [serverList, setServerList] = useState([]);
  const [server, setServer] = useState("");

  useEffect(() => {
    let mounted = true;
    const getserver = "/api/server";
    axios
      .get(getserver, {
        params: {},
      })
      .then((serv) => {
        if (mounted) {
          const d = serv.data;
          setServerList(d);
        }
      })
      .catch();
    return function cleanup() {
      mounted = false;
    };
  }, []);

  const items = serverList.map((s) => (
    <MenuItem id={s.id} value={s.id}>
      {s.description}
    </MenuItem>
  ));

  const handleChange = (event) => {
    setServer(event.target.value);
    onSelectServer(event.target.value);
  };

  return (
    <Box
      variant="gradient"
      bgColor="warning"
      borderRadius="lg"
      coloredShadow="warning"
      sx={{ mx: 2, display: "flex", flexDirection: "row-reverse", alignItems: "flex-end" }}
    >
      <Select
        sx={{ minWidth: 200, minHeight: 40 }}
        value={server}
        label="Servidor"
        onChange={handleChange}
      >
        {items}
      </Select>
      <MDTypography sx={{ mx: 2 }}>Servidor:</MDTypography>
    </Box>
  );
}
