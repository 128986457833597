/* eslint-disable import/named */
import { LOGIN } from "../action-types";
import initialState from "./initialState";

export default function login(state = initialState, action) {
  switch (action.type) {
    case LOGIN: {
      return {
        ...state,
        usuario: action.usuario,
      };
    }

    default:
      return state;
  }
}
