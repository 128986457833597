/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import { createStore } from "redux";
import rootReducer from "./reducers/usuarioReducer";

function configureStore() {
  return createStore(rootReducer);
}

const store = configureStore();

export default store;
