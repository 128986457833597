/* eslint-disable react/prop-types */

// Soft UI Dashboard React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
import MDBadge from "components/MDBadge";

// Images
import jbossLogo from "assets/vivit/jboss.png";
import spLogo from "assets/vivit/springboot.png";
import oracleLogo from "assets/vivit/oracle.png";

import axios from "axios";
import { useEffect, useState } from "react";

export default function podsTableData(server) {
  //  const [server, setServer] = useState();
  // const server = 1;

  const [data, setData] = useState([]);

  const location = `/api/server/${server}/pod`;

  useEffect(() => {
    let mounted = true;
    axios
      .get(location, {
        params: {},
      })
      .then((serv) => {
        if (mounted) {
          const d = serv.data;
          setData(d);
        }
      })
      .catch();
    return function cleanup() {
      mounted = false;
    };
  }, [server, data]);

  const Author = ({ image, name, email }) => (
    <MDBox display="flex" alignItems="center" lineHeight={1}>
      <MDAvatar src={image} name={name} size="sm" />
      <MDBox ml={2} lineHeight={1}>
        <MDTypography display="block" variant="button" fontWeight="medium">
          {name}
        </MDTypography>
        <MDTypography variant="caption">{email}</MDTypography>
      </MDBox>
    </MDBox>
  );

  const Job = ({ title, description }) => (
    <MDBox lineHeight={1} textAlign="left">
      <MDTypography display="block" variant="caption" color="text" fontWeight="medium">
        {title}
      </MDTypography>
      <MDTypography variant="caption">{description}</MDTypography>
    </MDBox>
  );

  function getLogo(type) {
    switch (type) {
      case "S":
        return spLogo;
      case "O":
        return oracleLogo;
      default:
        return jbossLogo;
    }
  }

  const columns = [
    { Header: "Servicio", accessor: "author", width: "45%", align: "left" },
    { Header: "Ultimo Control", accessor: "function", align: "left" },
    { Header: "Servicio / Puerto", accessor: "notificacion", align: "left" },
    { Header: "Estado", accessor: "status", align: "center" },
  ];

  const rows = data.map((pod) => {
    const logo = getLogo(pod.connectivity.type);
    const trz = pod.traza[pod.traza.length - 1];
    const activo = trz.status === "UP";
    return {
      author: <Author image={logo} name={pod.name} email={pod.description} />,
      function: (
        <Job
          title={trz.ejecution_date.substring(0, 10)}
          description={trz.ejecution_date.substring(11, 16)}
        />
      ),
      notificacion: <Job title={pod.connectivity.path} description={pod.connectivity.port} />,
      status: (
        <MDBox ml={-1}>
          <MDBadge
            badgeContent={activo ? "Activo" : "Inactivo"}
            color={activo ? "success" : "error"}
            variant="gradient"
            size="sm"
          />
        </MDBox>
      ),
    };
  });

  return { columns, rows };
}
