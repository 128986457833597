/**
=========================================================
* Material Dashboard 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import ComplexStatisticsCard from "examples/Cards/StatisticsCards/ComplexStatisticsCard";

// Dashboard components
import axios from "axios";
import React, { useState, useEffect } from "react";

function Dashboard() {
  const [dash, setDash] = useState([]);

  useEffect(() => {
    let mounted = true;
    axios
      .get("/api/server", { params: {} })
      .then((res) => {
        if (mounted) {
          setDash(res.data);
        }
      })
      .catch();

    return function cleanup() {
      mounted = false;
    };
  }, []);

  function podsEnabled(pods) {
    let activo = true;
    pods.forEach((pod) => {
      if (pod.traza[0].status !== "UP") {
        activo = false;
      }
    });
    return activo;
  }

  function estado(server) {
    let col = "info";
    let ic = "thumb_down";
    let lab = "El servidor está deshabilitado para su control";
    let desc = "Server Deshabilitado";
    let last = "";

    if (server.traza.status === "DOWN") {
      col = "error";
      ic = "thumb_down";
      lab = "El servidor está caído o no tiene conectividad";
      desc = "Server Caído";
      last = server.traza.ejecution_date;
    } else if (server.enabled === true) {
      if (podsEnabled(server.pods) === true) {
        col = "success";
        ic = "thumb_up";
        lab = "Todos los servicios Activos";
        desc = "Activo";
        last = server.traza.ejecution_date;
      } else {
        col = "warning";
        ic = "thumb_up";
        lab = "Hay algún servicio caído o no tiene conectividad";
        desc = "Servicio Caído";
        last = server.traza.ejecution_date;
      }
    }
    return { color: col, icon: ic, descripcion: desc, label: lab, ultEjecucion: last };
  }

  const renderItems = dash.map((srv) => {
    const srvdata = estado(srv);
    const ultEjecucion = `Ultimo Control: ${srv.traza[0].ejecution_date.substring(
      0,
      10
    )} ${srv.traza[0].ejecution_date.substring(11, 16)}`;
    return (
      <Grid item xs={4}>
        <MDBox mb={1.5}>
          <ComplexStatisticsCard
            color={srvdata.color}
            icon={srvdata.icon}
            title={srv.description}
            count={srv.name}
            percentage={{
              color: srvdata.color,
              amount: srvdata.descripcion,
              label: srvdata.label,
            }}
            podsCountActive={srv.pods.length}
            lastExec={ultEjecucion}
            ip={srv.connectivity.host}
            msg={srv.notification_type}
          />
        </MDBox>
      </Grid>
    );
  });

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3}>
        <Grid container spacing={2}>
          {renderItems}
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Dashboard;
